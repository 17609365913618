import { Button } from "@/block-system/brickz/components/ui/Button";
import { Field, FieldError } from "@/block-system/brickz/components/ui/Field";
import { TextInput } from "@/block-system/brickz/components/ui/TextInput";
import { FormContainer } from "@/block-system/components/forms/FormContainer";
import { toast } from "@/components/Toaster";
import { zodResolver } from "@hookform/resolvers/zod";
import { TRPCClientError } from "@trpc/client";
import { Spinner } from "block-system/components/Spinner";
import { ReCAPTCHA } from "components/ReCAPTCHA";
import { useEffect } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import styled from "styled-components";
import { trpc } from "utils/trpc";
import { z } from "zod";
import { Content } from "../";
import { zrpc } from "@/lib/zrpc";

export function StytchConsumerAuthForm(props: {
  captchaEnabled: boolean;
  stytchOrganizationId: string;
  isEditing: boolean;
}) {
  const { data: organization, isLoading } = zrpc.interfaces.useQuery(
    "get",
    "/api/interfaces/v0/stytch_organizations/{organization_id}",
    { params: { path: { organization_id: props.stytchOrganizationId } } },
    { throwOnError: true }
  );

  if (!organization || isLoading) {
    return <Spinner />;
  }

  const magicLinkEnabled = organization.allowed_auth_methods.magic_link;
  const googleEnabled = organization.allowed_auth_methods.google_oauth;

  const renderDivider = magicLinkEnabled && googleEnabled;

  return (
    <Content>
      <FormContainer className="gap-y-[12px]">
        {magicLinkEnabled ? (
          <MagicLinkForm
            captchaEnabled={props.captchaEnabled}
            isEditing={props.isEditing}
          />
        ) : null}
        {renderDivider ? <Divider /> : null}
        {googleEnabled ? (
          <SignInWithGoogleForm isEditing={props.isEditing} />
        ) : null}
      </FormContainer>
    </Content>
  );
}

const MagicLinkFormDataSchema = z.object({
  email: z
    .string()
    .min(1, "Email is required")
    .email("Please enter a valid email address"),
  captcha: z.string({ required_error: "Captcha is required" }),

  validatedSubmitCount: z.number(),
});

type MagicLinkFormData = z.infer<typeof MagicLinkFormDataSchema>;

function MagicLinkForm({
  captchaEnabled,
  isEditing,
}: {
  captchaEnabled: boolean;
  isEditing: boolean;
}) {
  const formSchema = captchaEnabled
    ? MagicLinkFormDataSchema
    : MagicLinkFormDataSchema.omit({ captcha: true });

  const {
    control,
    formState: { isSubmitting, errors, isSubmitSuccessful },
    handleSubmit,
    watch,
    getValues,
    reset,
    setError,
  } = useForm<MagicLinkFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      captcha: undefined,
      validatedSubmitCount: 0,
    },
  });

  const consumerAuth = zrpc.interfaces.useMutation(
    "post",
    "/api/interfaces/v0/internal/auth/stytch-auth-start"
  );

  const onSubmit: SubmitHandler<MagicLinkFormData> = async ({
    email,
    captcha,
  }) => {
    try {
      await consumerAuth.mutateAsync({
        body: {
          email,
          captcha,
        },
      });

      toast.success({
        message:
          "A link has been sent to your email inbox that will allow you to login.",
      });
    } catch (error) {
      const isTRPCClientError = error instanceof TRPCClientError;
      if (!isTRPCClientError || !error.data.errors) {
        setError("email", {
          message: "An error occurred, please try again.",
        });

        return;
      }

      for (const name in error.data.errors) {
        setError(name as keyof MagicLinkFormData, {
          type: "manual",
          message: error.data.errors[name],
        });
      }
    }
  };

  useEffect(() => {
    const { validatedSubmitCount } = getValues();
    /**
     * Using `reset` in the `onSubmit` does not work well.
     * The user might be able to submit the form twice if they are fast enough.
     */
    reset(
      {
        validatedSubmitCount: validatedSubmitCount + 1,
      },
      { keepValues: false }
    );
  }, [getValues, isSubmitSuccessful, reset]);

  const captchaKey = watch("validatedSubmitCount");
  return (
    /**
     * The `noValidate` allows us to customize look and feel of the error messages.
     *
     * Note that it is still imperative to use correct input types, even with `noValidate={true}`.
     * This is because having correct attributes on the inputs helps users who rely on screen readers.
     */
    <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <Controller
        name={"email"}
        control={control}
        render={({ field }) => {
          return (
            <Field
              label="Email address"
              error={errors.email?.message}
              renderInput={(fieldProps) => (
                <TextInput
                  {...field}
                  {...fieldProps}
                  type="email"
                  placeholder="Email address"
                  tabIndex={isEditing ? -1 : undefined}
                />
              )}
            />
          );
        }}
      />
      {captchaEnabled ? (
        <>
          <div className={"h-[20px]"} />
          <Controller
            name="captcha"
            control={control}
            render={({ field }) => {
              return (
                <Field
                  error={errors.captcha?.message?.toString()}
                  label="Captcha"
                  renderInput={() => (
                    <ReCAPTCHA
                      /**
                       * Ensure the captcha is reset when the form is submitted.
                       * Otherwise, if the user solved it correctly once, they would not have to solve it again upon submission.
                       */
                      key={captchaKey}
                      action="PROJECT_LOGIN"
                      onChange={(key) => {
                        field.onChange(key);
                      }}
                    />
                  )}
                />
              );
            }}
          />
        </>
      ) : null}
      <div className={"h-[20px]"} />
      <Button
        className="w-full"
        disabled={isSubmitting}
        isLoading={isSubmitting}
        type="submit"
        tabIndex={isEditing ? -1 : undefined}
      >
        Send magic link
      </Button>
    </form>
  );
}

/**
 * Adopted from https://developers.google.com/identity/branding-guidelines#custom-button
 */
const SignInWithGoogleButtonWrapper = styled.div`
  .gsi-material-button {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: WHITE;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: "Roboto", arial, sans-serif;
    font-size: 17px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition:
      background-color 0.218s,
      border-color 0.218s,
      box-shadow 0.218s;
    transition:
      background-color 0.218s,
      border-color 0.218s,
      box-shadow 0.218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    max-width: 400px;
    min-width: min-content;
  }

  .gsi-material-button .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }

  .gsi-material-button .gsi-material-button-content-wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  .gsi-material-button .gsi-material-button-contents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: "Roboto", arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .gsi-material-button .gsi-material-button-state {
    -webkit-transition: opacity 0.218s;
    transition: opacity 0.218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .gsi-material-button:disabled {
    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
  }

  .gsi-material-button:disabled .gsi-material-button-contents {
    opacity: 38%;
  }

  .gsi-material-button:disabled .gsi-material-button-icon {
    opacity: 38%;
  }

  .gsi-material-button:not(:disabled):active .gsi-material-button-state,
  .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
    background-color: #303030;
    opacity: 12%;
  }

  .gsi-material-button:not(:disabled):hover {
    -webkit-box-shadow:
      0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow:
      0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
  }

  .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
    background-color: #303030;
    opacity: 8%;
  }
`;

type SignInWithGoogleFormData = {
  serverError: undefined;
};

function SignInWithGoogleForm({ isEditing }: { isEditing: boolean }) {
  const methods = useForm<SignInWithGoogleFormData>({
    defaultValues: {
      serverError: undefined,
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const utils = trpc.useUtils();
  const onSubmit = async () => {
    try {
      /**
       * This is an RPC because it is much easier for us to handle the redirect.
       * If we were to create an API route and return 307 from the API route,
       * we would have to add additional code to the `fetch` call to handle the redirect.
       * (mainly setting the CORS headers and ensuring the fetch call follows the redirect).
       */
      const redirectUrl = await utils.projectAuth.googleOAuthRedirectUrl.fetch(
        undefined,
        {
          meta: { noToast: true },
        }
      );
      return window.location.assign(redirectUrl);
    } catch {
      setError("serverError", {
        message: "An error occurred. Please try again.",
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SignInWithGoogleButtonWrapper>
          <button
            disabled={isSubmitting}
            className="gsi-material-button"
            style={{ width: 340, height: 40 }}
            type="submit"
            tabIndex={isEditing ? -1 : undefined}
          >
            <div className="gsi-material-button-state"></div>
            <div className="gsi-material-button-content-wrapper">
              <div className="gsi-material-button-icon">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  style={{ display: "block" }}
                >
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  ></path>
                  <path
                    fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  ></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
              </div>
              <span className="gsi-material-button-contents">
                Sign in with Google
              </span>
              <span style={{ display: "none" }}>Sign in with Google</span>
            </div>
          </button>
        </SignInWithGoogleButtonWrapper>
        <SignInWithGoogleServerError />
      </form>
    </FormProvider>
  );
}

function SignInWithGoogleServerError() {
  const {
    register,
    formState: { errors },
  } = useFormContext<SignInWithGoogleFormData>();

  const serverError = errors.serverError?.message;
  return (
    <div>
      {/* This input is only here so that we do not have to manually reset the error whenever user tries to re-submit the form */}
      <input {...register("serverError")} type="hidden" aria-hidden={true} />
      <div role="alert">
        {serverError ? (
          <>
            <div className="h-[4px]" />
            <FieldError>{serverError}</FieldError>
          </>
        ) : null}
      </div>
    </div>
  );
}

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1px;
  background: #e2e7f0;
  margin: 24px 0;
`;

const DividerText = styled.span`
  background-color: #fff; // White background for the text
  padding: 0 10px;
`;

function Divider() {
  return (
    <DividerWrapper>
      <DividerText>OR</DividerText>
    </DividerWrapper>
  );
}
